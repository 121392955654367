<template>
  <div id="app">
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    <div class="container is-fluid ym-container">
      <div class="columns is-variable is-0 is-multiline is-mobile">
        <div :class="isAuthenticated ? 'column is-2 ym-column-left' : 'column is-2 ym-column-left ym-whitebg'">
          <Sidebar v-if="isAuthenticated" />
          <div class="ym-logo" v-else>
            <h1><router-link to="/" :style="{'background-image': `url(${storage?.content}${logo?.content})`}">SERVICEAPP</router-link></h1>
          </div>
        </div>        
        <div class="column is-12-mobile is-12-tablet is-10-desktop">
          <div class="is-12 is-hidden-desktop">
            <div class="ym-logo ym-logo-space-between">
              <h1><router-link to="/" :style="{'background-image': `url(${storage?.content}${logo?.content})`}">SERVICEAPP</router-link></h1>
              <section class="section section-userbar" v-if="isAuthenticated && dictionary && Object.keys(dictionary).length > 0">
                <button v-if="(calendar && calendar !== '' && calendar.is_active)" class="button is-small is-secondary" type="button" @click="isModalActive = !isModalActive"><icon name="calendar"></icon></button>
                <div class="userlogout"><a @click="logout" class="not"><icon name="log-out"></icon></a></div>
              </section>   
            </div>
          </div>
          <div class="ym-column-right">      
            <section class="section section-userbar is-hidden-touch" v-if="isAuthenticated && dictionary && Object.keys(dictionary).length > 0">
              <button v-if="(calendar && calendar !== '' && calendar.is_active)" class="button is-small is-secondary" type="button" @click="isModalActive = !isModalActive">{{ dictionary['calendar'] }}</button>
              <div class="userlogout"><span v-if="user">{{ user.firmname }}: {{ user.firstname }} {{ user.lastname }}</span><a @click="logout"><icon name="log-out"></icon></a></div>
              <portal to="BreadCrumbs"></portal>
            </section>                
            <router-view/>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="isModalActive" v-if="(calendar && calendar !== '' && calendar.is_active)" :width="720" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content content-iframe" v-html="calendar.content"></div>
        </div>
      </div>
    </b-modal>
    <NavBar v-if="isAuthenticated" />
    <div class="refresh-container" v-if="hashChanged">
      <div class="notification-body">
        <div class="notification-button" style="text-align: center;">
          <p>Aktualizacja jest dostępna. Zapisz całą bieżącą pracę i kliknij przycisk Aktualizuj poniżej.</p><br/>
          <p><b>Brak aktualizacji może skutkować błędami.</b></p>
        </div>
        <div class="refresh-button">
          <button class="button is-small is-primary" @click="reloadApp">Aktualizuj</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import Sidebar from '@/components/Sidebar.vue'
  import NavBar from '@/components/NavBar.vue'
  import {mapActions, mapGetters} from 'vuex'
  import { $axios } from '@/utils/axios'
  import store from './store'
  import { refreshPageMixin } from '@/mixins/refresh-page.mixin';

  // import {AUTH_LOGOUT} from '@/store/actions/auth'
  // import { $axios } from '@/utils/axios'


  export default {
    name: 'app',
    components: {
      Sidebar,
      NavBar,
    },    
    mixins: [refreshPageMixin],
    data() {
      return {
        color: '#e3001b',
        isModalActive: false,
      }
    },
    computed: {
      ...mapGetters({
        isLoading: 'isLoading',
        isAuthenticated: 'auth/isAuthenticated',
        storage: 'storage',
        logo: 'logo',
        user: 'auth/user',
        dictionary: 'dictionary',
        calendar: 'calendar',
      }),
      styleObject() {
        return {
          '--color': '#e3001b',
        }
      }       
    },      
    mounted() {

    },
    created: function () {
      let isAuthenticated = this.isAuthenticated
      this.getAppSettings()
      this.getAppDictionary()
      if (isAuthenticated) this.apiTechnicalUsers()
      $axios.interceptors.response.use(function (response) {
        return response;
      }, function (err) {
        if(err.response.status === 401 && err.response.config && !err.response.config.__isRetryRequest){
          if(isAuthenticated) {
            store.dispatch('auth/authLogout').then(() => {
              location.reload()
            })
          }
        }
        return Promise.reject(err.response);
      });      
    },  
    methods: {
      ...mapActions({
        getAppSettings: 'getAppSettings',
        getAppDictionary: 'getAppDictionary',
        authLogout: 'auth/authLogout',
        apiTechnicalUsers: 'auth/apiTechnicalUsers',

      }),  
      logout: function () {
        this.authLogout().then(() => location.reload())
      }
    }    
  }

</script>

<style>
.refresh-container {
  width: 100%;
  max-width: 310px;
  position: fixed;
  bottom: 15px;
  right: 15px;
  background-color: #fff;
  padding: 25px;
  box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
  z-index: 333;
}
.refresh-button {
  margin-top: 16px;
  display: flex;
  justify-content: center;
}
.section-userbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}
.userlogout {
  align-items: center;
}
.loading-overlay.is-full-page { z-index: 1999;}

.content-iframe{
  position: relative;
  height: 0;
  padding-bottom: 50%;
}

.content-iframe iframe,
.content-iframe object,
.content-iframe embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 851px) {
  .content-iframe {
    min-width: 80vw;
    min-height: calc(100vh - 210px);
  }
}
</style>
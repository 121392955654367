import axios from 'axios';

export const refreshPageMixin = {
  data() {
    return {
      currentHash: localStorage.getItem('arcadebee-version-hash') || '{{POST_BUILD_ENTERS_HASH_HERE}}',
      // currentHash: '{{POST_BUILD_ENTERS_HASH_HERE}}',
      hashChanged: false,
      newHash: ''
    }
  },
  created() {
    if (process.env.NODE_ENV === 'production') {
      this.checkVersion()
      this.initVersionCheck()
    }
  },
  methods: {
    initVersionCheck(frequency = 1000 * 60 * 2) {
      setInterval(() => {
        this.checkVersion();
      }, frequency);
    },
    async checkVersion() {
      try {
        const fileResponse = await axios.create({
          baseURL: window.location.origin,
        }, {headers: { 'Content-Type': 'application/x-www-form-urlencoded' }}).get('/version.json?t=' + new Date().getTime());

        this.newHash = fileResponse.data.hash;

        this.hashChanged = this.hasHashChanged(this.currentHash, this.newHash);
      } catch (error) {
        this.loading = false;
        if (!error.response) {
          this.errorStatus = 'Error: Network Error'
        } else {
          this.errorStatus = error.response.data.message;
        }
      }
    },
    hasHashChanged(currentHash, newHash) {
      return currentHash !== newHash;
    },
    reloadApp() {
      localStorage.setItem('arcadebee-version-hash', this.newHash);
      this.currentHash = this.newHash
      window.location.reload();
    }
  }
};